import React from "react";
//navbar footer
import Navbar from "./navbar";
import Footer from "./Footer";

//css
import "../style/Donation.css";
//router
import { NavLink } from "react-router-dom";
//bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Donation = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div id="donation">
        <Container fluid>
          <Row>
            <Col className="col-12 donation-banner">
              <div className="donation-banner-text">
                <div className="dona-banner-title  donation-red text-capitalize">
                  naksa chholing
                </div>
                <div className="home-banner-sub-title">Monastery</div>
                <p className="text-capitalize donation-red dona-banner">
                  empowering through education
                </p>
                <NavLink
                  to="/donation/donation-pay"
                  className="home-banner-btn btn text-capitalize">
                  help us Donate now
                </NavLink>
              </div>
            </Col>
            <div className="bg-yellow">
              <Col className="col-5 dona-line">
                <h1 className="dona-heading dona-red fw-bold">
                  Help Preserve Our Ancient Monastery: A Call for Donations
                </h1>
                Welcome to{" "}
                <span className="fw-bold dona-red ">
                  {" "}
                  Naksa Chholing Monastery{" "}
                </span>
                ,Lho,Gorkha's Living Heritage!
                <br />
                <br />
                Nestled within the quaint beauty of our village lies a treasure
                trove of history and spirituality—the Naksa Chholing Monastery.
                This ancient sanctuary has stood the test of time, a testament
                to the resilience of our community and the rich cultural
                tapestry that weaves through our village. Why Your Support
                Matters: <br />
                The Naksa Chholing Monastery, though lovingly preserved by the
                local community, requires urgent attention to ensure its
                continued existence for generations to come. Time, weather, and
                the natural aging process have taken their toll on this
                historical marvel, and restoration efforts are essential to
                safeguard its unique architecture and preserve its stories.{" "}
                <br /> <br />
                <span className="dona-red fw-bold">
                  How You Can Contribute:
                </span>
                <ul>
                  <li>
                    <span className="fw-bold">Monetary Donations</span>: Your
                    financial support will play a crucial role in funding the
                    restoration project. Contributions will go towards sourcing
                    quality materials, skilled craftsmen, and implementing
                    necessary conservation measures.
                  </li>
                  <li>
                    <span className="fw-bold"> In-Kind Contributions</span> : If
                    you possess expertise in restoration, construction, or
                    related fields, consider donating your skills or materials
                    to aid the restoration process.
                  </li>
                  <li>
                    <span className="fw-bold">Spread the Word</span>: Help us
                    amplify our cause by sharing this campaign on your social
                    media platforms, among friends, and within your community.
                    Every share counts!
                  </li>
                  <li>
                    <span className=" fw-bold">Volunteer</span>: If you're
                    passionate about preserving history, consider volunteering
                    your time and skills. Your hands-on involvement can make a
                    significant impact.
                  </li>
                </ul>
                <br />
                <span className="dona-red fw-bold">How to Donate</span>: <br />
                Visit our dedicated{" "}
                <NavLink
                  to="/donation/donation-pay"
                  className="dona-red text-decoration-none li-btn">
                  {" "}
                  Donate here{" "}
                </NavLink>
                to contribute and learn more about the campaign. Your support,
                whether big or small, will contribute to the lasting legacy of
                the Naksa Chholing Monastery.
                <br /> <br />
                <span className="dona-red fw-bold">Connect With Us</span>:{" "}
                <br />
                For inquiries, partnerships, or more information, please contact
                us at +977 9840779613.
                <br /> <br />
                Thank You for Being a Guardian of History!
                <br />
                Your generosity will not only restore a physical structure but
                will breathe life into the stories and traditions etched into
                the walls of the Naksa Chholing Monastery. Join us on this
                journey to preserve, protect, and celebrate our living heritage.
                <br />
                <br />
                <span className="fw-bold">
                  Gratefully,
                  <br />
                  The Naksa Chholing Gumba Preservation Committee <br />
                  Lho, Gorkha Nepal <br />
                  +977 9840779613
                </span>
              </Col>
            </div>
            {/*  
            =================== REMOVING OLD ABOUT DONATION TO NEW CONTENT=========
             <Col className="dona-about col-12">
              <Row className="dona-row">
                <div className="dona-top">
                  <Col className="col-6 help-grid right-border">
                    <div className="help-icon">
                      <i class="fa-solid fa-hands-holding-child"></i>
                    </div>
                    <div>
                      <div className="help-text text-uppercase">
                        Unity in society
                      </div>
                    </div>
                  </Col>
                  <Col className="col-6 help-grid">
                    <div className="help-icon">
                      <i class="fa-solid fa-book-open-reader"></i>
                    </div>
                    <div>
                      <div className="help-text text-uppercase">education</div>
                    </div>
                  </Col>
                </div>
                <Col className="col-12 order-sm-1 order-2 dona-small col-sm-6 bg-white d-flex flex-column align-items-start justify-content-center">
                  <div className="dona-why">
                    -{" "}
                    <span className="donation-red">
                      Ancient Archaeological, Historic, Religious and Culturally
                    </span>
                  </div>
                  <div className="dona-para p-2" data-aos="fade-right">
                    One of the ancient monasteries of Gorkha district. The
                    people of the whole Lho V.D.C. get together from time to
                    time and perform religious activities in this monastery.
                    Therefore people of that area have great belief in that
                    monastery. Manaslu Himalaya is just opposite of this
                    monastery, Buddha Himalaya and Ganesh Himalaya can be
                    clearly seen and wild-goat, danphe and Munal can be seen in
                    several numbers. The tourists who go to Larke Pass can be
                    attracted towards this monastery so that the income of this
                    monastery will be easily increased. But it is essential to
                    construct the way to this monastery. This monastery has the
                    land of 400 rapanis. The hurricane that took place in 2012
                    AD. blew off the roof of this monastery and the historical,
                    ancient, archaeological, religious and culturally very
                    important things may have disappeared due to poor physical
                    condition of the monastery. Therefore, immediate renovation
                    is needed of this monastery.
                  </div>
                </Col>
                <Col className="col-12 col-sm-6  order-sm-2 order-1 p-0">
                  <div className="dona-img"></div>
                </Col>
                <Col className="col-12 col-sm-6 p-0">
                  <div className="dona-img1"></div>
                </Col>
                <Col className="col-12 dona-small col-sm-6 bg-white d-flex flex-column align-items-start justify-content-center">
                  <div className="dona-why">
                    Your small <span className="donation-red">donation</span>
                    helps us!
                  </div>
                  <div className="dona-para">
                    Naksa chholing Monastery is located in Gandaki zone, Gorkha
                    district, Lho V.D.C., ward No. 9, Naja Tole. This monastery
                    was established by Lungten Namkha Chhotak Gyaljen and Lama
                    Shang Tsalpa eight hundred thirty years ago in 1187 A.D.
                    where Ven. (Tullku) Karma Lama is chief lama and Dorje
                    Chhiring is the patron. This monastery is a public monastery
                    under Ngingma sect, this monastery consists of 40 ft.
                    length, 15 ft. breadth and 20 ft. height having a roof of
                    zink, a wall of mud and stone. It has 70 lamas and land of
                    400 ropanis.
                  </div>
                </Col>
              </Row>
            </Col>
 */}
            <Col className=" dona-more col-12">
              <div className="dona-more-bg d-none d-sm-flex"></div>
              <div className="row dona-more-img-container">
                <div className="col-12 col-sm-6">
                  <div className="dona-more-img"></div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="dona-more-head donation-red">
                    {/* Ancient Historical Monastery  */}
                    Ancient <div className="space"></div> Historical Monastery
                  </div>
                  <div className="dona-more-para">
                    In ancient history the lama named Chhotak Gyaljen threw 2
                    flying stones from a hill called Gungsyu and fell down at
                    the place where now Naksa chholing monastery is located and
                    there made a long line. While the stone was flying and
                    falling down in eight places, a woman saw it when she was
                    working in the field and she thought it would be a bad lock
                    to see such stone, so she hit the stone with her own cloth,
                    then the stone remained there. Now, that stone can be seen
                    even now. Eight stupas are made in the places where the
                    stone has fallen. This monastery is lengthy in shape. Once a
                    person saw a big stone changed into gold at the time when he
                    had gone up to that monastery to feed the flour to yaks. He
                    thought of taking that gold while returning and took off his
                    shoes and put them on the gold but he found that the gold
                    had disappeared when he was returned later on. The gold
                    which was stuck on the lace of the shoes was taken out and
                    offered to the monastery. There is a legend which can still
                    be heard that while putting the gold on the face of the
                    image of Guru padmaSambhava, he spoke there suggesting not
                    to use the gold only for him but to use it in the images
                    remaining left and right of him too.
                  </div>

                  <NavLink
                    to="/donation/donation-pay"
                    className="home-banner-btn btn text-capitalize">
                    help us Donate now
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Donation;
