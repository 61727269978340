import React from 'react'
import "../style/loader.css"
function Loader() {
  return (
    <div> 
        <span class="loader"></span>

    </div>
  )
}

export default Loader