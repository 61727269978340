import React from "react";

//navbar footer
import Navbar from "../navbar";
import Footer from "../Footer";

//css
import "../../style/History.css";
//bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const History = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div id="history">
        <Container fluid>
          <Row className="history-banner">
            <Col className=" col-12 history-main-banner"></Col>
          </Row>
          <Row className="w-90 ms-auto text-end">
            <Col className=" col-4 text-uppercase history-title ">history</Col>
          </Row>
          <Row className="history-detail-row">
            <Col className="col-12 col-lg-5 hg-img">
              <div className="history-img-grid">
                {/* <div className="hg-item1"></div>
                <div className="hg-item2"></div>
                <div className="hg-item3"></div>
                <div className="hg-item4"></div>
                <div className="hg-item5"></div> */}
              </div>

              <div className="hg-line"></div>
              <div className="hg-line2"></div>
            </Col>
            <Col className="col-12 col-lg-7 ps-lg-5">
              <div className="history-detail-title">
                NAKSA CHHOLING MONASTERY
              </div>
              <div className="history-detail-para">
                Is located in Gandaki zone, Gorkha district, Lho V.D.C., ward
                No. 9, Naja Tole. This monastery was established by Lungten
                Namkha Chhotak Gyaljen and Lama Shang Tsalpa eight hundred
                thirty years ago in 1187 A.D. where Ven. (Tullku) Karma Lama is
                chief lama and Dorje Chhiring is the patron. This monastery is a
                public monastery under Ngingma sect, this monastery consists of
                40 ft. length, 15 ft. breadth and 20 ft. height having a roof of
                zink, a wall of mud and stone. It has 70 lamas and land of 400
                ropanis.
                <br />
                In ancient history the lama named Chhotak Gyaljen threw 2 flying
                stones from a hill called Gungsyu and fell down at the place
                where now Naksa Chholing monastery is located and there made a
                long line. While the stone was flying and falling down in eight
                places, a woman saw it when she was working in the field and she
                thought it would be a bad lock to see such stone, so she hit the
                stone with her own cloth, then the stone remained there. Now,
                that stone can be seen even now. Eight stupas are made in the
                places where the stone has fallen. This monastery is lengthy in
                shape. Once a person saw a big stone changed into gold at the
                time when he had gone up to that monastery to feed the flour to
                yaks. He thought of taking that gold while returning and took
                off his shoes and put them on the gold but he found that the
                gold had disappeared when he was returned later on. The gold
                which was stuck on the lace of the shoes was taken out and
                offered to the monastery. There is a legend which can still be
                heard that while putting the gold on the face of the image of
                Guru padmaSambhava, he spoke there suggesting not to use the
                gold only for him but to use it in the images remaining left and
                right of him too.
                <br /> Naksa Chholing Monastery is well known as historical,
                ancient, archaeological, religious and culturally very important
                monastery. This monastery can be reached in two hours from Syo
                village through a stiff way where different types of spiritual
                dances are performed. Ancient wall painting masks and religious
                books written in gold remain there. For the lama spiritual dance
                two persons wear the mask of a bird while dancing. Dances are
                shown while putting souls in the idol of Buddhist gods.
              </div>
              {/* <div className="history-icon">
                <div className="history-fb">
                  <i className="fa-brands fa-square-facebook"></i>
                </div>
                <div className="history-insta">
                  <i class="fa-brands fa-instagram"></i>
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default History;
