import React from "react";
//css
import "../style/Footer.css";
//bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div id="footer">
        <Container fluid className="footer-container">
          <Row className="footer-row ">
            <Col className="col-md-3  col-12 order-1 footer-small ">
              <div className="footer-head text-capitalize">About Us</div>
              <div className="footer-para">
                One of the ancient monasteries of Gorkha district. The people of
                the whole Lho V.D.C. get together from time to time and perform
                religious activities in this monastery. Established by Lungten
                Namkha Chhotak Gyaljen and Lama Shang Tsalpa eight hundred
                thirty years ago in 1187 A.D.
              </div>
            </Col>
            <Col className="col-md-3 col-5 order-md-2 order-3 footer-small ">
              <div className="footer-head text-capitalize ">quick links</div>
              <div className="footer-list text-capitalize">
                <NavLink to="/about/history" className="nav-link">
                  About us
                </NavLink>
              </div>
              <div className="footer-list text-capitalize">
                <NavLink to="/donation" className="nav-link">
                  donation
                </NavLink>
              </div>
              <div className="footer-list text-capitalize">
                <NavLink to="/news" className="nav-link">
                  news
                </NavLink>
              </div>
              <div className="footer-list text-capitalize">
                <NavLink to="/contact" className="nav-link">
                  contact us
                </NavLink>
              </div>
            </Col>
            <Col className="col-md-3 col-6  order-md-2 order-2 d-none d-sm-flex footer-small ">
              <div className="footer-map">
                <iframe
                  className="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7007.583735217901!2d84.723899!3d28.576012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39bfe34e55299709%3A0x391ddf242c8d3d6a!2zTmFrc2EgQ2hoeW9pbGluZyBHdW1iYSwg4KSo4KS-4KSV4KWN4KS44KS-IOCkm-CljeCkr-Cli-Ckh-CksuCkv-CkmSDgpJfgpYvgpKjgpY3gpKrgpL4!5e0!3m2!1sen!2snp!4v1701597604556!5m2!1sen!2snp"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
            <Col className="col-md-3 col-7 order-4 footer-small ">
              <div className="footer-head text-capitalize">contact Us</div>

              <div className="footer-icon">
                <div className="footer-fb ">
                  <a
                    href="https://www.facebook.com/profile.php?id=61550850871490&mibextid=ZbWKwL"
                    target="_blank"
                    className="nav-link">
                    <i className="fa-brands fa-square-facebook"></i>
                  </a>
                </div>
                {/* <div className="footer-insta">
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </div> */}
              </div>
              <div className="footer-list text-capitalize">+977 9840779613</div>
              <div className="footer-list ">naksamonastery@gmail.com</div>

              <div className="footer-list text-capitalize">
                Lho, Gorkha, Nepal
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="cre-container" fluid>
        <Row className="w-100">
          <Col className="col-12 order-5 web-credit">
            Website Design and developed by
            <a
              href="https://github.com/Tseringdhoundup9813"
              className="cre-name">
              {""} Tsering
            </a>
            {""} and {""}
            <a href="https://github.com/Tenzin1000" className="cre-name">
              Sangey
            </a>
            , 2023.
          </Col>
        </Row>
      </Container>
    </div>
  );
}
