import axios from "axios";


const Instance = axios.create(
    
    {
    baseURL:"https://backend.naksagumba.org.np/api/naksa",
  
})
export default Instance;
